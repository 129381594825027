import React from 'react';
import parse from 'html-react-parser';

const TypeBicycle = ({data, ref}) => {
    return (
        <div ref={ref} className='item__bottom-container'>
            <div className='type__bicycle-container'>
                <div className='bicycle__img'>
                    <img className='bicycle-img' src={data.route_bike_type.thumb} alt={data.route_bike_type.title}/>
                </div>
                <div className='bicycle__description'>
                    <h6 className='bicycle__title'>{data.route_bike_type.title}</h6>
                    <p className='bicycle__description-text'>{parse(data.route_bike_type.description)}</p>
                </div>
            </div>
        </div>
    );
};

export default TypeBicycle;