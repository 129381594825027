import React from 'react';
import { Chart } from "react-google-charts";


const ElevationRoute = ({route, chart}) => {
 
    const color = (route !== null && route.fields.path_color !== undefined) ? route.fields.path_color : '#0288d1';
    const options = {
        title: "",
        hAxis: { 
            title: "km", 
            titleTextStyle: { 
                color: color
            },
 
        },
        vAxis: { 
            format: '# m',
            minValue: 0,
            textPosition: "in",
            titleTextStyle: { 
                color: color,
            },
            ticks: [300]
        },
        legend: 'none',
        colors: [color],
        chartArea: { 
            width: "100%", 
            height: "80%",
            top: "10%",
            bottom: "5%",
         },
         axisTitlesPosition: 'out',
         
    }
    if (chart.length) {
        return (
            <div key={route.data.ID} className='elevation__route'>
                <Chart
                    chartType="AreaChart"
                    data={chart}
                    options={options}
                    width="100%"
                    height="160px"
                />
            </div>
        );
    }    
};

export default ElevationRoute;