import React from 'react';

const Brands = ({data}) => {

    let brands  = [];
    if (data !== null && data !== undefined) {
        const pagedata = data.pagedata;
        if (pagedata !== null ) {
            brands = pagedata.brands ? pagedata.brands : [];
        } 
    }

    return (
        <div className='brands'>
            <div className="container">
                <div className="brands__container">
                {brands.map((key, index) => {
                        return (
                            <div key={index + key.title}>
                                <img className='brand_logo' src={key.sizes.medium ?? key.url ?? ''} alt={key.alt !== '' ? key.alt: key.title}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Brands;