import React from 'react';

const NecessaryEquipment = ({data, ref}) => {
    return (
        <div ref={ref} className='item__bottom-container'>
            <div className='equipment__container'>
                <div className='necessary__equipment-container'>
                    {data.route_equipment && data.route_equipment.map((item, index, {length}) => (
                        <div key={index} className='equipment__item'>
                            <div className='orange-square'></div>
                            <p className='equipment-item-text'>{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NecessaryEquipment;