import React from 'react';
import message from '../../../Assets/images/message.png'
import phone from '../../../Assets/images/phone.png'
import location from '../../../Assets/images/location.png'
import Skeleton from 'react-loading-skeleton'

const Contacts = ({data, componentRef}) => {

    let pd = {};
    let trans = {};
  
    if (data !== null && data !== undefined) {
      const pagedata = data.pagedata;
      const strings = data.strings;  
      if (pagedata !== null) {
        pd = {
          "contact_address"     : pagedata.contact_address ?? false,
          "contact_email"       : pagedata.contact_email ?? false,
          "contact_phone"       : pagedata.contact_phone ?? false,
        };
      }
      if (strings !== null && strings !== undefined) {
        trans = data.strings;
      }
    }

    return (
        <div className='contacts' ref={componentRef}>
            <div className='container'>
                <div className='contacts__content'>
                    <div className="contacts__content-container">
                        <div className="contacts__content-container-img">
                            <img className='contacts__img' src={location} alt="location"/>
                        </div>
                        <div className="contacts__content-container-text">
                            <h4 className='contacts__title'>{trans.text_address || <Skeleton />}</h4>
                            <p className='contacts__description'>{pd.contact_address || <Skeleton />}</p>
                        </div>
                    </div>
                    <a href={'mailto:' + pd.contact_email}  className="contacts__content-container">
                        <div className="contacts__content-container-img">
                            <img className='contacts__img' src={message} alt=""/>
                        </div>
                        <div className="contacts__content-container-text">
                            <h4 className='contacts__title'>{trans.text_email || <Skeleton />}</h4>
                            <p className='contacts__description'>{pd.contact_email || <Skeleton />}</p>
                        </div>
                    </a>
                    <a href={'tel:' + pd.contact_phone} className="contacts__content-container">
                        <div className="contacts__content-container-img">
                            <img className='contacts__img' src={phone} alt=""/>
                        </div>
                        <div className="contacts__content-container-text">
                            <h4 className='contacts__title'>{trans.text_phone || <Skeleton />}</h4>
                            <p className='contacts__description'>{pd.contact_phone || <Skeleton />}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contacts;