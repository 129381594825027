
import './scss/Styles.scss';
import React from 'react';
import Header from "./scripts/components/Header/Header";
import Main from "./scripts/components/Main/Main";

const App = () => {
  return (
      <div className='App'>
          <Main/>
      </div>
  );
};

export default App;
