import React from 'react';

import ReactPlayer from 'react-player'

const Embed = ({url}) => {

    return (
        <div className="container video-responsive">
           <div className="iframe">
            <ReactPlayer 
                    url={url} 
                    loop={true}
                    muted={true}
                    playing={true}
                    height="auto"
                    width="100%"
                    controls={true}
                    config={
                        {
                            youtube: {
                                playerVars: {
                                    showinfo: 0,
                                    rel: 0
                                }
                            }
                        }
                    }
                />
           </div>
        </div>
    );
};

export default Embed;
