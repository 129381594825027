import React from 'react';

const InformationRoute = ({route, trans, elevation}) => {
    return (
        <div className='information__routes'>

            <div className='first-part'>
                {(route.fields.filters.route_distance || elevation.distance ) && <div className='information-item'><span className="description-text">{trans.text_distance}:</span><span className="value-text">{route.fields.filters.route_distance ? route.fields.filters.route_distance : elevation.distance} {trans.text_km}</span></div>}
                {route.fields.filters.route_difficulty_label && <div className="information-item"><span className="description-text">{trans.text_difficulty}:</span><span className="value-text">{route.fields.filters.route_difficulty_label} </span></div>}
                {elevation.max && <div className="information-item"><span className="description-text">{trans.text_max_elevation}:</span><span className="value-text">{elevation.max} {trans.text_meters}</span></div>}
                {elevation.gain && <div className="information-item"><span className="description-text">{trans.text_elevation_gain}:</span><span className="value-text">{elevation.gain} {trans.text_meters}</span></div>}
            </div>
            <div className='second-part'>
                {route.fields.route_type && <div className="information-item"><span className="description-text">{trans.text_route_type}:</span><span className="value-text">{route.fields.route_type}</span></div>}
                {elevation.min && <div className="information-item"><span className="description-text">{trans.text_min_elevation}:</span><span className="value-text">{elevation.min} {trans.text_meters}</span></div>}
                {elevation.loss && <div className="information-item"><span className="description-text">{trans.text_elevation_loss}:</span><span className="value-text">{elevation.loss} {trans.text_meters}</span></div>}
            </div>

        </div>
    );
};

export default InformationRoute;