import SimpleSliderMission from "./SimpleSliderMission";
import Skeleton from 'react-loading-skeleton'
import parse from 'html-react-parser';

const MainMission = ({data, componentRef}) => {

    let pd = {};
    let trans = {};


    if (data !== null && data !== undefined) {
        const pagedata = data.pagedata;
        const strings  = data.strings;
        
        if (pagedata !== null ) {
            pd = {
                "description"  : pagedata.our_mission_description ?? '',
                "images"       : pagedata.our_mission_gallery ? pagedata.our_mission_gallery : [],
            };
        } 
        if (strings !== null && strings !== undefined ) {
            trans = data.strings;
        }
    }
    if (pd.images !== null) {
        return (
            <div className='main__mission' ref={componentRef}>
                <div className="container">
                    <div className="main__mission-content">
                        <div className="main__mission-slider">
                            {<SimpleSliderMission images={pd.images}/> || <Skeleton />}
                        </div>
                        <div className="section-description">
                            <h3 className='section-title'>{trans.text_our_mission || <Skeleton />}</h3>
                            <div className="description__section-container">
                                <p className='about__content-description'>{parse(pd.description) || <Skeleton />}</p>
                            </div>
                            <div className='about__content__decoration-line'></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
};

export default MainMission;