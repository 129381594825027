import React, { useState, useEffect, useRef, useCallback } from "react";
import MainHero from "./MainHero";
import Embed from "./Embed";
import MainAbout from "./MainAbout";
import MainMission from "./MainMission";
import Map from "../Map/Map";
import Brands from "../Brands/Brands";
import Contacts from "../Contacts/Contacts";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import DocumentMeta from 'react-document-meta';
import PageLoader from "../../../Assets/images/loader-v1.gif"

import axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";


// const CALLBACK_URL = process.env.REACT_APP_HOST_DOMAIN + process.env.REACT_APP_HOST_API_PREFIX;

const BASE_URL = `/api/v2/get_pagedata`;

const getSearchParameters = () => {
  const prmstr = window.location.search.substr(1);
  return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
}

const transformToAssocArray = ( prmstr ) => {
  let params = {};
  const prmarr = prmstr.split("&");
  for ( var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split("=");
      params[tmparr[0]] = tmparr[1];
  }
  return params;
}


const getLocale = () => {
  const params = getSearchParameters();
  const urlLocale = params.lang ?? null;
  const locale = localStorage.getItem("locale");
  const defaultLocale = "ro";

  if (urlLocale) {
    return urlLocale;
  }
  if (!locale) {
    return defaultLocale;
  }
  return locale;
};

const useAxios = ({ url }) => {
    const CALLBACK_URL = process.env.REACT_APP_HOST_DOMAIN + process.env.REACT_APP_HOST_API_PREFIX;
    const get_url = `${CALLBACK_URL}${url}`;
    const [response, setResponse] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const fetchData = useCallback(() => {
      axios
        .get(get_url)
        .then((response) => {
          setResponse(response.data);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [get_url]);
    useEffect(() => {
      fetchData();
    }, [fetchData]); // will fetch again when url changes (read about useEffect dependency array)
    return { response, error, loading };
  };



const Main = () => {
  //Reference
  const routeRef    = useRef(null);
  const aboutRef    = useRef(null);
  const contactRef  = useRef(null);
  const missionRef  = useRef(null);

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const JumpTo = (location) => {
    switch (location) {
      case 'routes': scrollDown(routeRef); 
      break;
      case 'about': scrollDown(aboutRef);
      break;
      case 'contacts': scrollDown(contactRef);
      break;
      case 'mission': scrollDown(missionRef);
      break;
      default:
      break
    }
  }

  const [locale, setLocale] = useState(getLocale);
  const [jsonData, setData] = useState();
  const [route_distance, setRouteDistance] = useState(0);
  
  const { response, loading } = useAxios({
    url: `${BASE_URL}&lang=${locale}`,
  });

  useEffect(() => {
    if (response?.data && Object.keys(response.data).length > 0) {
      setData(response.data);
    }
    // NOTE: if response.? syntax is not supported:
    // if (response && response.data && Object.keys(response.data).length > 0) {
    //     setData(response.data);
    //   }
  }, [response]);

  const changeLanguage = (language) => {
    setLocale(language);
  };

  if (loading) {
    return <>{ 
        <div className="page-loader">
          <img src={PageLoader}/>
        </div>
      }
      </>;
  }

//   if (response !== null) { 
//     console.log(response);
// TODO: adjust logic with skeleton loader
//   }

    if (response !== null) {

      const meta = {
        title: `Moldova on Bikes | ${response.pagedata.banner_title}`,
        description: response.pagedata.banner_description ?? '',
        // canonical: 'https://cubiciclete.md',
        meta: {
            charset: 'utf-8',
            name: {
                // keywords: ''
            }
        }
      };

      const toTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }
    

      return (
          <>
          <DocumentMeta {...meta} />
          {<Header onJumpTo={JumpTo} onChangeLanguage={changeLanguage} data={response} toTop={toTop} /> }
          {<MainHero onJumpTo={JumpTo} data={response} /> }
          {response.pagedata.video_url && <Embed url={response.pagedata.video_url} />}
          {<Map key={locale} componentRef={routeRef} locale={locale} useAxios={useAxios} data={response}/>}
          {<Brands data={response}/>}
          {<MainAbout componentRef={aboutRef} data={response} />}
          {<MainMission componentRef={missionRef}  data={response} />}
          {<Contacts componentRef={contactRef} data={response}/>}
          {<Footer onJumpTo={JumpTo} data={response} toTop={toTop}/>}
          </>
      );
    }
};

export default Main;
