import React, {useState, createRef, useEffect} from 'react'


import closeBtn from '../../../Assets/icons/close-icon.svg'
import location from '../../../Assets/icons/orange-location.png'
import difficulty from '../../../Assets/icons/orange-dificulty.png'
import distance from '../../../Assets/icons/orange-distance.png'
import bicycle from '../../../Assets/icons/orange-bicycle.png'
import MySelect from "../../utils/CustomSelect";
import CustomSlider from "../../utils/CustomSlider";
import {ReactComponent as ArrowRight} from "../../../Assets/icons/item-arrow-right.svg";


function Filter({setFilters, filters, trans, showMenu, setShowMenu}) {

    useEffect(() => {
        if (showMenu === true) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'initial'
        }
    }, [showMenu])

    // const getRange = [parseInt(filters.distances.min),parseInt(filters.distances.max)];
    const [form_start_point, setStartPoint]     = useState(null);
    const [form_difficulty, setDifficulty]      = useState(null);
    const [form_distance_min, setDistanceMin]   = useState(parseInt(filters.distances.min));
    const [form_distance_max, setDistanceMax]   = useState(parseInt(filters.distances.max));
    const [defaultRange, setDefaultRange]       = useState([parseInt(filters.distances.min),parseInt(filters.distances.max)]);
    const [range, setRange]                     = useState([parseInt(filters.distances.min),parseInt(filters.distances.max)]);
    const [form_type, setType]                  = useState(null);

    //Refs
    const resetsp = createRef();
    const resetrd = createRef();
    const resetbt = createRef();

    //Submit to API call
    const handleSubmit = event => {
        event.preventDefault();
       
        let filters = '';

        if (form_start_point !== null && form_start_point !== '') {
            filters += `&start_point=${form_start_point}`;
        }

        if (form_difficulty !== null && form_difficulty !== '') {
            filters += `&route_difficulty=${form_difficulty}`;
        }

        if (form_distance_min !== null && form_distance_min !== '') {
            filters += `&route_distance_min=${form_distance_min}`;
        }

        if (form_distance_max !== null && form_distance_max !== '') {
            filters += `&route_distance_max=${form_distance_max}`;
        }

        if (form_type !== null && form_type !== '') {
            filters += `&route_bike=${form_type}`;
        }

        setFilters(filters);
        setShowMenu(false);

    }
    //Reset inputs
    const resetSlider = () => {
        // setDefaultRange([parseInt(filters.distances.min),parseInt(filters.distances.max)]);
        setDistanceMin(defaultRange[0]);
        setDistanceMax(defaultRange[1]);
    };

    const resetSelect = () => {
        //Inputs
        resetsp?.current?.reset();
        resetrd?.current?.reset();
        resetbt?.current?.reset();

        //Submit values
        setStartPoint(null);
        setDifficulty(null);
        setType(null);
    };

    const resetFilters = () => {
        //Reset
        setRange(defaultRange);
        resetSlider();
        resetSelect();
    };

    const onSlide = (range) => {
        setDistanceMin(range[0]);
        setDistanceMax(range[1]);
        setRange(range);
    }

    return (
        <>
            <div onClick={() => {
                setShowMenu(false)
            }} className={showMenu ? 'overlay' : ''}>
            </div>
            <form onSubmit={handleSubmit} className={showMenu ? 'filter__content-active' : 'filter__content'}>
                <div className="filter__top-section">
                    <div className="top__filter-container">
                        <h4 className='filter-title'>{trans.text_filters}</h4>
                        <img onClick={() => {
                            setShowMenu(false)
                        }} className='close-filter' src={closeBtn} alt=""/>
                    </div>
                    <div className="main-content">
                        <div className="container__filter-input">
                            <div className="filter__input-description">
                                <img src={location} alt="location"/>
                                <p className='description__input-text'>{trans.text_start_point}</p>
                            </div>
                            <MySelect 
                                ref={resetsp} 
                                options={filters.start_points} 
                                trans={trans}
                                updated={event => setStartPoint(event.value)} 
                            />

                        </div>
                        <div className="container__filter-input">
                            <div className="filter__input-description">
                                <img src={difficulty} alt="dificulty"/>
                                <p className='description__input-text'>{trans.text_difficulty}</p>
                            </div>
                            <MySelect 
                                ref={resetrd} 
                                options={filters.route_difficulties} 
                                trans={trans}
                                updated={event => setDifficulty(event.value)} 
                            />

                        </div>
                        <div className="container__filter-input">
                            <div className="filter__input-description">
                                <img src={distance} alt="distance"/>
                                <p className='description__input-text'>{trans.text_distance}</p>
                                <div className="slider_range">
                                    <span className="slider_range_min">{form_distance_min} {trans.text_km}</span>
                                    <span> - </span>
                                    <span className="slider_range_max">{form_distance_max} {trans.text_km}</span>
                                </div>
                            </div>
                            <CustomSlider 
                                trans={trans} 
                                onSlide={onSlide} range={range} 
                                defaultRange={defaultRange} 
                            />

                        </div>
                        <div className="container__filter-input">
                            <div className="filter__input-description">
                                <img src={bicycle} alt="bicycle"/>
                                <p className='description__input-text'>{trans.text_bike_type}</p>
                            </div>
                            <MySelect 
                                ref={resetbt} 
                                options={filters.bycicle_types} 
                                trans={trans}
                                updated={event => setType(event.value)} 
                            />

                        </div>
                    </div>
                </div>
                <div className="filter__bottom-section">
                    <div onClick={event => resetFilters(event)} className='filter__btn-menu reset'>{trans.text_reset_filters}</div>
                    <button type="submit" className='filter__btn-menu apply'>{trans.text_apply} <ArrowRight className='filter__menu-arrow-right'/>
                    </button>
                </div>
            </form>
        </>
    )
}

export default Filter