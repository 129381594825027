import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as ArrowLeft} from "../../../Assets/icons/arrow-left-filter-item.svg";
// import location from '../../../Assets/icons/location-icon.png'
// import cetate from '../../../Assets/images/cetate.png'
import InteresPoints from "./InteresPoints";
import NecessaryEquipment from "./NecessaryEquipment";
import TypeBicycle from "./TypeBicycle";
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import {ReactComponent as Localization} from "../../../Assets/icons/localization.svg";
import {ReactComponent as Instruments} from "../../../Assets/icons/equipment-icon.svg";
import {ReactComponent as Bicycle} from "../../../Assets/icons/bicycle-tab.svg";
import PageLoader from "../../../Assets/images/loader-v2.gif"
import parse from 'html-react-parser';

const ItemSectionDescription = ({route, trans, goToPrev, setSlidePage,setShowInformationRoute,setChangeMapContainer}) => {
    const dataTabs = [{
        name: trans.text_interest_points,
        icon: <Localization className='tab-icon'/>
    }, {
        name: trans.text_equipment,
        icon: <Instruments className='tab-icon'/>
    }, {
        name: trans.text_bike_type,
        icon: <Bicycle className='tab-icon'/>
    }]
    const [selectedTab, setSelectedTab] = useState(0);
    const bottomContainer = useRef()



    if(!route) {
        return <>{ 
            <div className="page-loader map">
              <img src={PageLoader}/>
            </div>
          }
          </>;
    }
    
    return (
        <div className='item__section-description'>
            <div className='item__section_description-text'>
                <div className='item__section_description-title'>
                    <ArrowLeft onClick={() => {
                        goToPrev()
                        setSlidePage(1)
                        setShowInformationRoute(false)
                        setChangeMapContainer(false)
                    }} className='arrow__slider-back'/>
                    <h5 className='item-title'>{route.data.post_title}</h5>
                </div>
                <div className='item__section_description-text'>
                    <p className='item__description-paragraph'>{parse(route.data.post_content)}</p>
                </div>
            </div>
            <div className="item__section__description-places">
                <div className='container-scroll'>
                    <div className="item__description_places-titles">
                        {dataTabs.map((item, index) => (
                            <div key={index} onClick={() => {
                                setSelectedTab(index)
                            }} className={selectedTab === index ? 'selected__item-tab' : 'item-tab'}>
                                <div
                                    className={selectedTab === index ? 'selected__tab-title' : 'tab-title'}>{item.icon}<span
                                    className='show-text'>{item.name}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {selectedTab === 0 && (
                    <InteresPoints data={route.fields} innerRef={bottomContainer}/>
                )}
                {selectedTab === 1 && (
                    <NecessaryEquipment data={route.fields} innerRef={bottomContainer}/>
                )}
                {selectedTab === 2 && (
                    <TypeBicycle data={route.fields} innerRef={bottomContainer}/>
                )}
            </div>
        </div>
    );
};

export default ItemSectionDescription;