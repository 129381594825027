import React, {useState} from 'react';
import {ReactComponent as ButtonIcon} from "../../../Assets/icons/button-hero-icon.svg";
import {ReactComponent as LinkedIn} from "../../../Assets/icons/linked-in.svg";
import {ReactComponent as Instagram} from "../../../Assets/icons/instagram.svg";
import {ReactComponent as Facebook} from "../../../Assets/icons/facebook.svg";
import {ReactComponent as Website} from "../../../Assets/icons/website.svg";

import Skeleton from 'react-loading-skeleton'




const MainHero = ({data, onJumpTo}) => {

    let pd = {};
    let trans = {};
    let linkedin = '';
    let instagram = '';
    let facebook = '';
    let website = '';

    if (data !== null) {
        const pagedata = data.pagedata;
        const strings  = data.strings;
        
        if (pagedata !== null && pagedata !== undefined) {
            pd = {
                "title"         : pagedata.banner_title ?? '',
                "description"   : pagedata.banner_description ?? '',
                "background"    : pagedata.banner_image ?? '',
                "linkedin"      : pagedata.linkedin ?? false,
                "website"       : pagedata.website ?? false,
                "facebook"      : pagedata.facebook ?? '',
                "instagram"     : pagedata.instagram ?? '',
            };
        } 
        if (strings !== null && strings !== undefined) {
            trans = data.strings;
        }
    }
    if (pd.website !== '') {
        website = 
        <a target="_blank" className='hero__link-linkedIn' href={pd.website}>
            <Website className='linkedIn-hero'/>
        </a>
    }

    if (pd.linkedin !== '') {
        linkedin = 
        <a target="_blank" className='hero__link-linkedIn' href={pd.linkedin}>
            <LinkedIn className='linkedIn-hero'/>
        </a>
    }
    
    if (pd.instagram !== '') {
        instagram = 
        <a target="_blank" className='hero__link-instagram' href={pd.instagram}>
            <Instagram className='instagram-hero'/>
        </a>
    }

    if (pd.facebook !== '') {
        facebook = 
        <a target="_blank" className='hero__link-facebook' href={pd.facebook}>
            <Facebook className='facebook-hero'/>
        </a>
    }
    

    return (
       
        <div className='main' style={ pd.background !== false ? {  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url(${pd.background})`} : {}}>
            <div className="container">
                <div className="main__content">
                    <div className="main__content-text">
                        <h1 className='main__title'>{ pd.title || <Skeleton count={2}/> }</h1>
                        <p className='main__description'>{ pd.description || <Skeleton /> } </p>
                        <button className='hero-btn' onClick={() => onJumpTo('routes')}>
                            <span className='main__content__btn_text'>{ trans.text_select_route || <Skeleton /> }</span>
                            <div className='img-button'></div>
                        </button>
                    </div>
                    <div className="icons-content">
                        <div className='main__decoration-line'></div>
                        <div className='icons-container'>
                            {website}
                            {linkedin}
                            {instagram}
                            {facebook}
                        </div>
                        <div className='main__decoration-line'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainHero;