import Range from 'rc-slider';
import 'rc-slider/assets/index.css';


const CustomSlider = ({trans, onSlide, range, defaultRange}) => {

    const handleChange = (event) => {
      // console.log(event);
      onSlide(event)
    }

    return (
      <>       
        <Range 
          range
          value={range}
          min={defaultRange[0]} 
          max={defaultRange[1]}
          defaultValue={defaultRange}
          onChange={handleChange}
        />
      </>

    )
}
export default CustomSlider