import React from 'react';
import SimpleSliderAbout from "./SimpleSliderAbout";
import Skeleton from 'react-loading-skeleton'
import parse from 'html-react-parser';

const MainAbout = ({data, componentRef}) => {

    let pd = {};
    let trans = {};


    if (data !== null && data !== undefined) {
        const pagedata = data.pagedata;
        const strings  = data.strings;
        
        if (pagedata !== null ) {
            pd = {
                "description"  : pagedata.about_us_description ?? '',
                "images"       : pagedata.about_us_gallery ? pagedata.about_us_gallery : [],
            };
        } 
        if (strings !== null && strings !== undefined ) {
            trans = data.strings;
        }
    }
    if (pd.images !== null) {
        return (
            <div className='about' ref={componentRef}>
                <div className="container">
                    <div className="about__content">
                        <div className="about__content-text">
                            <h3 className='section-title'>{trans.text_about_us || <Skeleton />}</h3>
                            <div className="description__section-container">
                                <div className='about__content-description'>{parse(pd.description) || <Skeleton />}</div>
                            </div>
                            <div className='about__content__decoration-line'></div>
                        </div>
                        <div className='about__content-slider'>
                          { <SimpleSliderAbout images={pd.images} slidersToshow='1' showArrows='false'/> || <Skeleton /> }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default MainAbout;