import {debounce} from 'lodash';
import React, {useState} from 'react';
import location from "../../../Assets/icons/location-icon.png";
// import cetate from "../../../Assets/images/cetate.png";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const InteresPoints = ({data, ref}) => {

    let default_image = ''
    if (data.route_points_of_interest[0]) {
        default_image = data.route_points_of_interest[0].point_image ?? ''
    }
  
    const [point_image, setPointImage] = useState(default_image); 
    const [photoIndex, setIndex] = useState(0); 
    const [isOpen, setOpen] = useState(false);

    const showImage = (image) => {
        setPointImage(image);
    }

    const openImage = (image) => {
        if (images[image] !== '') {
            //Check if has image
            setIndex(image);
            setOpen(true);
        }
    }

    let images = [];

    if (data.route_points_of_interest) {
        data.route_points_of_interest.map((item, index) => {
            images.push(item.point_image);
        })
    }

    
    return (
        <div ref={ref} className="item__bottom-container">
            <div className='item__description_places-container'>
                <div className='interes__points'>
                    {data.route_points_of_interest && data.route_points_of_interest.map((item, index, {length}) => (
                    <div key={index} className='icons__location-container'>
                        <div className='container__location' onMouseOver={() => showImage(item.point_image)} onClick={() => openImage(index)}>
                            <img className='icon__item-img' src={item.point_icon ? item.point_icon : location} alt=""/>
                            <p className='description__icon-text'>{item.point_title}</p>
                        </div>
                        {index + 1 !== length ? 
                        <div className='distance__locations'>
                            <div className="distance__location-line"></div>
                            <div className="distance__location-number">{item.point_distance}</div>
                        </div>
                        : ''
                        }
                    </div>
                    ))}
                </div>
                <div className='icons__image'>
                    <img src={point_image} alt=""/>
                </div>
            </div>
            {images && isOpen && <Lightbox
                loop
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => setOpen(false)}
                onMovePrevRequest={() =>
                    setIndex((photoIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() => 
                    setIndex((photoIndex + 1) % images.length)
                }
            />}
        </div>
    );
};

export default InteresPoints;