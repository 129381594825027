import React, { useEffect, useRef, useState } from "react";
// import ro_RO from '../../../Assets/icons/romina.png'
// import ru_RU from '../../../Assets/icons/rusa.png'
// import en_US from '../../../Assets/icons/engleza.png'
import arrowBottom from "../../../Assets/icons/arow bottom.svg";
import sprite from "../../../Assets/logo/sprite.svg";
import { ReactComponent as Localization } from "../../../Assets/icons/header-localization.svg";
import { ReactComponent as Union } from "../../../Assets/icons/Union.svg";
import { ReactComponent as Phone } from "../../../Assets/icons/phone.svg";

import { ReactComponent as DownloadICon } from "../../../Assets/icons/download.svg";
import MenuBurger from "./MenuBurger"
import useOnClickOutside from "../../hooks/useOnClickOutside";

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};

const Header = ({ data, onChangeLanguage, onJumpTo, toTop }) => {
  let pd = {};
  let languages = null;
  let active_language = null;
  const scrollDirection = useScrollDirection();

  const trans = data.strings;
  const pagedata = data.pagedata;

  if (data.languages !== undefined) {
      languages = data.languages.list;
      active_language = data.languages.active;
  }
  if (pagedata !== null) {
    pd = {
      "download_guide"  : pagedata.download_guide ?? false,
    };
  }

  const langRef = useRef();
  useOnClickOutside(langRef, () => {
    setIsLangOpen(false);
  });

  // const [lang, setLang] = useState()

  function setLang(locale) {
    onChangeLanguage(locale);
    localStorage.setItem("locale", locale);
  }

  const [islangOpen, setIsLangOpen] = useState(false);

  let language_dropdown = "";

  if (languages !== null && active_language !== null) {
    language_dropdown = (
      <div
        ref={langRef}
        onClick={() => {
          setIsLangOpen(!islangOpen);
        }}
        className="header__language"
      >
        <img className="language-image" src={active_language.flag_url} alt="" />
        <h6 className="language-text">{active_language.slug}</h6>
        <img className="arrow-bottom" src={arrowBottom} alt="" />
        <ul
          className={`dropdown-language ${
            islangOpen ? "lang-open" : "lang-close"
          }`}
        >
          {languages.map((item, index) => {
            return (
              <li key={index}
                onClick={() => {
                  setLang(item.slug);
                }}
                className="language-link"
              >
                <img src={item.flag_url} alt="" /> {item.slug}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  let download = '';
  if (pd.download_guide) {
    download =  <a target="__blank" className="download" href={pd.download_guide.url} download>
                  {trans.text_download_guide}
                  <DownloadICon file={pd.download_guide} className="download-icon" />
                </a>
  }


  if (data !== null && data !== undefined) {
    return (
      <header className={`header ${scrollDirection == "down" ? "hide" : ""}`}>
        <div className="container">
          <div className="header-content">
            <a onClick={toTop} className="logo_anchor">
              { data.pagedata.header_logo && <img className="logo invert" src={data.pagedata.header_logo} />}
              { !data.pagedata.header_logo && 
                <svg className="logo">
                  <use href={sprite + "#Union"}></use>
                </svg>
              }
            </a>
            <div className="language__nav">
              <nav>
                <ul className="nav-links">
                  <li onClick={() => onJumpTo('routes')}>
                    <a
                      className="header__link"
                    >
                      <Localization className="header__link-icon" />
                      {trans.text_find_route}
                    </a>
                  </li>
                  <li onClick={() => onJumpTo('about')}>
                    <a
                      className="header__link"
                    >
                      <Union className="header__link-icon" />
                      {trans.text_about_us}
                    </a>
                  </li>
                  <li onClick={() => onJumpTo('contacts')}>
                    <a
                      className="header__link"
                    >
                      <Phone className="header__link-icon" />
                      {trans.text_contacts}
                    </a>
                  </li>
                </ul>
              </nav>
              
              {language_dropdown}
              {download}

              <MenuBurger 
                onJumpTo={onJumpTo} 
                data={data} 
                trans={trans} 
                download={download}
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
};

export default Header;
