import React, {useState, useEffect} from 'react';
import closeBtn from '../../../Assets/icons/close-icon.svg'
import location from '../../../Assets/icons/Icon-location simple.svg'
import people from '../../../Assets/icons/Icon-people.svg'
import contact from '../../../Assets/icons/Icon-call.svg'
import { ReactComponent as Menu } from "../../../Assets/icons/burger-menu.svg";
import sprite from "../../../Assets/logo/sprite.svg";

const MenuBurger = ({onJumpTo, data, download, trans}) => {


    const [menuOpen, setMenu ] = useState(false);
    const burgerClasses = menuOpen ? 'open' : '';

    useEffect(() => {
        if (menuOpen === true) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'initial'
        }
    }, [menuOpen])

    return (
        <>
            <Menu onClick={() => {setMenu(true)}} className="header-menu" />
            <div className={`burger_menu ${burgerClasses}`}>
                <div className="burger_header">
                    <svg className="logo logo-black">
                        <use href={sprite + "#Union"}></use>
                    </svg>
                    <img onClick={() => {setMenu(false)}} className='close-filter' src={closeBtn} alt=""/>
                </div>
                <ul className="navs">
                    <li className="nav-item" 
                        onClick={() => { 
                            onJumpTo('routes');
                            setMenu(false);
                        }}>
                        <span className="nav-icon">
                            <img src={location}/>
                        </span>
                        <span className="nav-text">{trans.text_find_route}</span>
                    </li>
                    <li className="nav-item" 
                        onClick={() => { 
                            onJumpTo('about');
                            setMenu(false);
                        }}>
                        <span className="nav-icon">
                            <img src={people}/>
                        </span>
                        <span className="nav-text">{trans.text_about_us}</span>
                    </li>
                    <li className="nav-item" 
                        onClick={() => { 
                            onJumpTo('contacts');
                            setMenu(false);
                        }}>
                        <span className="nav-icon">
                            <img src={contact}/>
                        </span>
                        <span className="nav-text">{trans.text_contacts}</span>
                    </li>
                </ul>
                
                <div className="burger_footer">
                {download}
                </div>

                <div className="burger_footnote">
                    { data.pagedata.facebook && <a className="note" target="_blank" href={data.pagedata.facebook}>{trans.text_facebook}</a>}
                    { data.pagedata.instagram && <a className="note" target="_blank" href={data.pagedata.instagram}>{trans.text_instagram}</a>}
                    { data.pagedata.linkedin && <a className="note" target="_blank" href={data.pagedata.linkedin}>{trans.text_linkedin}</a>}
                </div>
                
            </div>
        </>
    );
};

export default MenuBurger;