import React, {createRef, useEffect} from 'react';
import {ReactComponent as FilterIcon} from "../../../Assets/icons/filter.svg";
import closeIcon from "../../../Assets/icons/close-icon.svg";
import MapIcon from "./MapIcon";
import {ReactComponent as ArrowRight} from "../../../Assets/icons/item-arrow-right.svg";
import {useState, useRef} from "react";
import {useIsOverflow} from "../../hooks/useIsOverflow";
import locationIcon from "../../../Assets/icons/location-icon.png";
import orangeHome from "../../../Assets/icons/orange-home.png";
import route from "../../../Assets/icons/route.png";
import redHome from "../../../Assets/icons/red-home.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Pagination from "./Pagination";
import debounce from 'lodash.debounce';


const FilterSection = ({getTerm, selectRoute, routes, trans, setShowFilter, goToNext, setSlidePage,setShowInformationRoute,setChangeMapContainer}) => {
    

    const [showOverflowContainer, setShowOverflowContainer] = useState(true)
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(2)
    const [selectedItem, setSelectedItem] = useState(null)
    const refContainerItems = React.useRef();
    const isOverflow = useIsOverflow(refContainerItems);
    const dimensions = useWindowDimensions()
  

    let maxIcons = dimensions.width > 1160 ? 7 : dimensions.width < 1160 && dimensions.width > 1050 ? 5 : dimensions.width < 1050 && dimensions.width > 768 ? 3 : dimensions.width < 768 ? 6 : ''

    const ref = useRef(null)
    const [isCloseShown, setIsCloseShown] = useState(false)
    const handleClick = () => {
        ref.current.value = '';
        setIsCloseShown(false)
        getTerm('');
    }
    useEffect(() => {
        if (isOverflow === false) {
            setShowOverflowContainer(false)
        } else {
            setShowOverflowContainer(true)
        }
    }, [isOverflow])

    const handleSearch = (e) => {
        // setSearchTerm(e.target.value)
        getTerm(e.target.value);
        if (e.target.value.length >= 1) {
            setIsCloseShown(true)
        } else {
            setIsCloseShown(false)
        }
    }

   
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const loadedPosts = Object.values(routes);
    const currentPosts = loadedPosts.slice(indexOfFirstPost, indexOfLastPost)
    const currentData = dimensions.width < 767 ? currentPosts : loadedPosts;
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const Noresult = () => {
        return <><div className="no-result">{trans.text_no_routes}</div></>;
    };


    return (

        <div className='filter__container'>
            <div className='filter__container-search'>
                <div onClick={() => {
                    setShowFilter(true)
                }} className='filter__btn'>
                    <h6 className='title-filter'>{trans.text_filters}</h6>
                    <FilterIcon className='filter-icon'/>
                </div>
                <div className='search__bar'>
                    <img src={closeIcon}
                         onClick={handleClick}
                         className={isCloseShown ? 'close__icon' : 'close__icon hide__close-icon'}
                         alt=""/>
                    <input ref={ref} onChange={debounce(handleSearch, 300)} type="text" className='search__input'
                           placeholder={`${trans.text_search_route} (${loadedPosts.length} ${trans.text_routes})`}/>
                </div>
            </div>
            {<div ref={refContainerItems}
                 className={isOverflow && dimensions.width > 767 ? 'container__items-overflow' : 'container__items'}>
                { currentData.length > 0 && currentData.map((item,index) => (
                    <div key={index} onClick={() => {
                        setSelectedItem(item.data.ID)
                        selectRoute(item)
                        setChangeMapContainer(true)
                    }} className={selectedItem === index ? 'route__item-active' : 'route__item'}>
                        <div className="route__item-name">
                            <div className='route__item-img'></div>
                            <h5 className='route__item-title'>{item.data.post_title}</h5>

                        </div>
                        <div className="route__item-right">
                            <div className='route__item-icons'>
                            
                                {
                                item.fields.route_icons && item.fields.route_icons.map((icon,index) => (
                                    
                                    index < maxIcons ? 
                                    <img key={index} className="route_icon" src={icon ?? ''} alt='' />
                                    : ''
                                ))}
                                {
                                item.fields.route_icons && item.fields.route_icons.length - maxIcons > 0 && (
                                        <div key={index} className='rest__icons'>+{item.fields.route_icons.length - maxIcons}</div>
                                    )
                                }

                            </div>

                            <div className="items__buttons">
                            {item.fields.map_link && <a href={item.fields.map_link} target="_blank" className="item__button-show">
                                <h6 className='item__btn-name btn-white'>{trans.text_see_map}</h6>
                            </a> }
                            <div onClick={() => {
                                goToNext()
                                setSlidePage(2)
                                selectRoute(item)
                                setShowInformationRoute(true)
                            }} className='item__button-details'><h6
                                className='item__btn-name btn-black'>{trans.text_details}</h6>
                                <ArrowRight className='item__btn-img'/>
                            </div>
                        </div>
                        </div>
                    </div>
                )) || <Noresult />
                }

            </div>
            }
            <div className='pagination'>
                <Pagination postsPerPage={postsPerPage} paginate={paginate} totalPosts={loadedPosts.length}/>
            </div>
        </div>
    );
};

export default FilterSection;