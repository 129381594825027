import React, {useState} from 'react';
import {ReactComponent as ArrowLeft} from "../../../Assets/icons/arrow-left-pagination.svg";
import {ReactComponent as ArrowRight} from "../../../Assets/icons/arrow-right-pagination.svg";

const Pagination = ({postsPerPage, totalPosts, paginate}) => {
    const [selectedPage, setSelectedPage] = useState(1)
    const pages = [];
    const delim = 1;
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pages.push(i)
    }

    const page = (number, index) => {
        return  <li onClick={() => {
            paginate(number)
            setSelectedPage(number)
        }} key={number} className={selectedPage === index + 1 ? 'selected-page' : 'page-item'}>
            <div
                className='page-container-text'>{number}</div>
        </li> 
    }
    if (totalPosts > postsPerPage) {
        return (
            <nav className='container-pages'>
                <div onClick={() => {
                    paginate(selectedPage - 1);
                    setSelectedPage(selectedPage - 1)
                }} className={selectedPage === 1 ? 'arrow-disabled' : 'arrow-active'}><ArrowLeft
                    className={selectedPage === 1 ? "arrow-icon-disabled" : 'arrow-icon-active'}/></div>
                <ul className='pagination'>
                    {pages.map((number, index) => (
                    (number === 1 )? page(number,index) : 
                    (number <= delim && selectedPage <= delim ) ? page(number,index) : 
                    //  number === pages.length ? page(number,index) : 
                    number === selectedPage + 1 ? page(number,index) : 
                    number === selectedPage - 1 ? page(number,index) : 
                    number === selectedPage ? page(number,index) : ''

                    ))}
                </ul>
                <div onClick={() => {
                    paginate(selectedPage + 1)
                    setSelectedPage(selectedPage + 1)
                }} className={selectedPage === pages.length ? "arrow-disabled" : 'arrow-active'}><ArrowRight
                    className={selectedPage === pages.length ? "arrow-icon-disabled" : 'arrow-icon-active'}/></div>
            </nav>
        );
    }
};

export default Pagination;