import React from 'react';
import sprite from '../../../Assets/logo/sprite.svg'
import {ReactComponent as LinkedIn} from "../../../Assets/icons/linked-in.svg";
import {ReactComponent as Instagram} from "../../../Assets/icons/instagram.svg";
import {ReactComponent as Facebook} from "../../../Assets/icons/facebook.svg";
import {ReactComponent as DownloadIcon} from "../../../Assets/icons/download.svg";


const Footer = ({data, onJumpTo, toTop}) => {

    let pd = {};
    let trans = {};
  
    if (data !== null && data !== undefined) {
      const pagedata = data.pagedata;
      const strings = data.strings;  
      if (pagedata !== null) {
        pd = {
          "contact_address"     : pagedata.contact_address ?? false,
          "contact_email"       : pagedata.contact_email ?? false,
          "contact_phone"       : pagedata.contact_phone ?? false,
          "download_guide"      : pagedata.download_guide ?? false,
          "footer_description"  : pagedata.footer_description ?? false,
          "facebook"            : pagedata.facebook ?? false,
          "instagram"           : pagedata.instagram ?? false,
          "linkedin"            : pagedata.linkedin ?? false,
        };
      }
      if (strings !== null && strings !== undefined) {
        trans = data.strings;
      }
    }

    let $download = '';
    if (pd.download_guide) {
      $download =  <a target="__blank" className="footer__btn" href={pd.download_guide.url} download>
                    {trans.text_download_guide}
                    <DownloadIcon file={pd.download_guide} className="download-icon" />
                  </a>
                  
    }


    return (
        <footer className='footer'>
            <div className='container'>
                <div className="footer-content">
                    <div className="footer__description">
                        <a onClick={toTop} className="logo_anchor">
                            { data.pagedata.header_logo && <img className="logo invert" src={data.pagedata.header_logo} />}
                            { !data.pagedata.header_logo && 
                                <svg className="logo">
                                    <use href={sprite + "#Union"}></use>
                                </svg>
                            }
                        </a>
                        <p className='footer__description-text'>{pd.footer_description}</p>
                        {$download}
                    </div>
                    <div className="footer__navigation">
                        <div className="footer__about">
                            <h4 className='footer__title'>{trans.text_about_us}</h4>
                            <ul className='footer__list'>
                                <li className='footer__list-link' onClick={() => onJumpTo('routes')}><a >{trans.text_find_route}</a></li>
                                <li className='footer__list-link' onClick={() => onJumpTo('about')}><a >{trans.text_about_us}</a></li>
                                <li className='footer__list-link' onClick={() => onJumpTo('mission')}><a >{trans.text_our_mission}</a></li>
                            </ul>
                        </div>
                        <div className="footer__contacts">
                            <h4 className="footer__title">{trans.text_contacts}</h4>
                            <ul className='footer__list'>
                                <li className='footer__list-link'><a
                                    href={'mailto:' + pd.contact_email}>{pd.contact_email}</a></li>
                                <li className='footer__list-link'><a href={'tel:' + pd.contact_phone}>{pd.contact_phone}</a></li>
                                <li className='footer__list-link'><a>{pd.contact_address}</a></li>
                            </ul>
                        </div>
                        <div className="footer-media">
                            <h4 className="footer__title">{trans.text_socialmedia}</h4>
                            <ul className='footer__list-media'>
                                <li>
                                    <a className='link__linked-in' target="_blank" href={pd.linkedin}>
                                        <LinkedIn className='linked-in'/>
                                    </a>
                                </li>
                                <li>
                                    <a className='link__instagram' target="_blank" href={pd.instagram}>
                                    <Instagram className='instagram'/>
                                    </a>
                                </li>
                                <li>
                                    <a className='link__facebook' target="_blank" href={pd.facebook}>
                                        <Facebook className='facebook'/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;