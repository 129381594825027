import Select from "react-select";
import {useState, forwardRef, useImperativeHandle} from "react";


const MySelect = forwardRef((props, ref)  => {
    const [selectedOption, setOption] = useState(null);

    useImperativeHandle(ref, () => ({
        reset() {
            resetHandler();
        }
    }));
    
    const handleChange = (selectedOption) => {
        setOption(selectedOption);
        props.updated(selectedOption);
    };

    const resetHandler = () => {
        setOption(null);
    }


    const selectStyles = {
        control: (styles, state) =>
            ({
                ...styles, backgroundColor: 'white',
                '&:hover': {
                    borderColor: 'gray'
                },
                borderColor: state.isFocused ? 'gray' : '#E7E5E4',
                boxShadow: 'none',
                cursor: 'pointer',
                height: '42px',
                borderRadius: '10px',
                border: '2px solid #DFE0DF'
            }),

        indicatorSeparator: (styles) => ({
            ...styles, display: 'none'
        }),
        menuList: (styles) => ({
            ...styles, height: 'auto',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            padding: '0',
        }),
        menu: (styles) => ({
            ...styles, borderRadius: '7px',
            border: '1px solid gray',
            overflow: 'hidden'

        }),
        option: (styles, {data, isFocused, isDisabled, isSelected}) => {

            return {
                ...styles, height: '45px',
                backgroundColor: isFocused ? '#F9F9F9' : 'white',
                color: isSelected || isFocused ? 'black' : '#42403F',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
            }
        },
        placeholder: (styles) => {
            return {
                ...styles,
                lineHeight: '26px',
                fontSize: '16px',
                paddingLeft: '7px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400'

            }
        },
        singleValue: (styles) => {
            return {
                ...styles,
                color: '#42403F',
                lineHeight: '26px',
                fontSize: '16px',
                paddingLeft: '7px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400'
            }
        }

    }

    return (

        <Select 
            // isClearable
            value={selectedOption} 
            onChange={handleChange} 
            styles={selectStyles} 
            noOptionsMessage={() => props.trans.text_no_options} 
            placeholder={props.trans.text_select_option} 
            isSearchable={false} 
            options={props.options}

        />

    )
});
export default MySelect