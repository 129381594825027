import React, {Component, useState} from "react";
import Slider from "react-slick";
import imageSlider1 from '../../../Assets/images/imageSlider1.png'
import imageSlider2 from '../../../Assets/images/imageSlider2.png'
import imageSlider3 from '../../../Assets/images/imageSlider3.png'
import arrowRight from "../../../Assets/icons/arrow-right.svg";
import arrowLeft from "../../../Assets/icons/arrow-left.svg";
import {forEach} from "lodash";


const SimpleSliderMission = ({images}) => {

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={arrowRight} alt=""
                 className='about__arrow-right'
                 style={{...style, display: "block"}}
                 onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={arrowLeft} alt=""
                 className='about__arrow-left'
                 style={{...style, display: "block"}}
                 onClick={onClick}

            />

        );
    }


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        accessibility: false,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };

    return (
        <div className='slider__my-mission'>
            <Slider {...settings}>
                {images && images.map((key, index) => {
                    return (
                        <div key={index} className='container__img-slider'>
                            <img className='mission__slider-img' src={key.sizes.medium ?? key.url} alt={key.alt !== '' ? key.alt: key.title}/>
                            <span className='description-img'>{key.caption !== '' ? key.caption: key.title}</span>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
}

export default SimpleSliderMission