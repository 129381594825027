import React, {useState} from "react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import FilterSection from "./FilterSection";
import ItemSectionDescription from "./ItemSectionDescription";


const SimpleSliderMap = ({gotoNext, carousel, KMLSwitch, selectRoute, route, getTerm, routes, trans, setShowFilter,setShowInformationRoutes,setChangeMapContainer}) => {
    const [slidePage, setSlidePage] = useState(1)

    const gotoPrev = () => {
        carousel?.current?.slickPrev();
        KMLSwitch();
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        focusOnSelect: false,
        swipe:false,
        arrows:false,
        accessibility: false
    };

    

    return (
        <div className={slidePage === 1 ? 'slider_bike_first-slide' : 'slider_bike_second-slide'}>
            <Slider {...settings} ref={carousel}>
                {
                    <FilterSection 
                        getTerm={getTerm} 
                        selectRoute = {(route)=> {selectRoute(route)}} 
                        routes={routes} 
                        trans={trans} 
                        setChangeMapContainer={setChangeMapContainer} 
                        setShowInformationRoute={setShowInformationRoutes}
                        setSlidePage={setSlidePage} 
                        goToNext={(value) => gotoNext(value)} 
                        setShowFilter={setShowFilter}
                    /> 
                    || <Skeleton/>
                }
                {
                    <ItemSectionDescription 
                        trans={trans} 
                        route={route} 
                        setChangeMapContainer={setChangeMapContainer} 
                        setShowInformationRoute={setShowInformationRoutes} 
                        setSlidePage={setSlidePage} 
                        goToPrev={gotoPrev} 
                    /> 
                    || <Skeleton/> 
                }
            </Slider>
        </div>
    );
}

export default SimpleSliderMap