import React, {useEffect, useState, useRef} from 'react';
import GoogleMapReact from 'google-map-react'
import Filter from "./Filter";
import SimpleSliderMap from "./SimpleSliderMap";
import InformationRoute from "./InformationRoute";
import ElevationRoute from "./ElevationRoute";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Skeleton from 'react-loading-skeleton'
import PageLoader from "../../../Assets/images/loader-v2.gif"

const settings = {
    center: {
        lat: 47.2527105,
        lng: 28.8602822
    },
    zoom: 9,
    minzoom: 10,
    polyline: {
        strokeColor: "#0288d1",
        strokeOpacity: 0.8,
        strokeWeight: 6,
        ends: {
            scale: 10,
            strokeOpacity : 0.3,
            strokeWeight:  20
        }
    }
};
const mapStyles = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "hue": "#000000",
            },
            {
                "lightness": 0
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#005eff"
            },
            {
                "saturation": -100
            },
            {
                "lightness": -3
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f5f7fb"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "hue": "#000000"
            },
            {
                "saturation": -100
            },
            {
                "lightness": -100
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": -100
            },
            {
                "visibility": "on"
            },
            {
                "hue": "#005eff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#c6d0e1"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c6d0e1"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#c6d0e1"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#c6d0e1"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#bbbbbb"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 26
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "hue": "#ffffff"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 100
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d0d5dc"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d0d5dc"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d0d5dc"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d8dce2"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
    }
]

const getElevationData = ({results, route_distance}) => {
 
    let elevations = [];
    let currentEl = 0;
    let nextEl = 0;
    let elLoss = 0;
    let elGain = 0;

    if (!results) {
        return {
            distance: route_distance
        }
    }

    results.map((data, index) => {
        const el = parseFloat(data.elevation);
    
        currentEl = nextEl;
        nextEl = el;
      
        const delta = nextEl - currentEl;

        if (delta > 0) {
            elGain += delta;
        } else {
            elLoss += delta;
        }

        elevations.push(el);
    })

    const response = {
        max     : Math.max(...elevations).toFixed(2),
        min     : Math.min(...elevations).toFixed(2),
        gain    : Math.abs(elGain).toFixed(2),
        loss    : Math.abs(elLoss).toFixed(2),
        distance: route_distance
    }

    return response;
   
}

const Map = ({locale, useAxios, data, componentRef}) => {

    const ROUTES_URL = '/api/v2/get_routes';
    const trans = data.strings ?? [];
    const [changeMapContainer, setChangeMapContainer] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [showInformationRoute, setShowInformationRoute] = useState(false)
    const dimensions = useWindowDimensions()

    const [route, setRoute] = useState(null);
    const [showKML, setShowKML] = useState(true);
    const [path, setPath] = useState(route ? route.fields.route_path : []);


    const [startData, setStartData] = useState(
        {
            content: 'START',
            label: ''
        }
    );
    const [endData, setEndData] = useState(
        {
            content: 'END',
            label: ''
        }
    );


    const [jsonData, setRoutes] = useState();
    const [kmlurl, setKML] = useState(process.env.REACT_APP_MAP_KML);
    const [elevationData, setElevation] = useState({});
    const [elevationChart, setElevationChart] = useState({});

    const [route_filters, setFilters] = useState('');
    const [searchTerm, setTerm] = useState('');

    const selectRoute = (route) => {
        setRoute(route);
        setShowKML(false);

        setStartData(
            {
                content: route?.fields?.filters?.start_point,
                label: ''
            }
        );

        setEndData(
            {
                content: route?.fields?.filters?.end_point,
                label: ''
            }
        );
        setPath(Object.values(route.fields.route_path) ?? null);
    };

    const KMLSwitch = () => {
        setRoute(null);
        setPath([]);
        setShowKML(true);
    };

    // const GoogleMarker = ({src, id}) => <div className="google_point_marker"><img id={id} src={src}/> </div> || <Skeleton />;
    
    const getTerm = (query) => {
      setTerm(query);
    };

    let search = '';
    if (searchTerm !== null && searchTerm !== '') {
        search = `&title=${searchTerm}`;
    }
    const { response, loading } = useAxios({
        url: `${ROUTES_URL}&lang=${locale}${search}${route_filters}`,
    });

    const plotElevation = ({ results, route_distance }) => {
        const points = results.results;

        setElevation(getElevationData(results,route_distance));
        let cd = 0;
        let chart = [["Distance","Elevation"]];
        for (let i = 0; i < points.length; i++) {
            //Current distance
            cd = (i * (parseFloat(route_distance) / parseFloat(points.length))).toFixed(2);
            chart.push([ `${cd} km`, parseFloat(points[i].elevation.toFixed(2)) ]);
        }
        setElevationChart(chart);
    }
   
    const displayPathElevation = (elevator, map, maps) => {
        // Display a polyline of the elevation path.
 
        const color = (route !== null && route.fields.path_color !== undefined) ? route.fields.path_color : settings.polyline.strokeColor;
       
        const shadow = new maps.Polyline({
            path: path,
            strokeColor: color,
            strokeOpacity: 0.3,
            strokeWeight: settings.polyline.strokeWeight * 3,
            map: map,

        });

        const polyline = new maps.Polyline({
          path: path,
          strokeColor: color,
          strokeOpacity: settings.polyline.strokeOpacity,
          strokeWeight:settings.polyline.strokeWeight,
          map: map,
          zIndex: 10
        });

        const drawMarkers = (poi, maps) => {
            poi && poi.map((key,index) => {
                const marker = new maps.Marker({
                        position: { lat: key.point_coordinates.lat, lng: key.point_coordinates.lng},
                        sName: "",
                        map: map,
                        // optimized: false,
                        icon: {
                            url: key.point_icon,
                            scaledSize: new maps.Size(30,30),
                        },
                      
                    });
                    //Marker markdown
                    const markerData = {
                        content: `<div class="google_point_container">
                            <strong>${key.point_title}</strong>
                            ${key.point_image && '<img class="google_point_image" src=' + key.point_image + '/>'} 
                        </div>`
                    }
                    const infowindow = new maps.InfoWindow({
                        content: markerData.content,
                        ariaLabel: '',
                    });
                    //Event for infowindow
                    marker.addListener("click", () => {
                        // Close active
                        if(activeInfoWindow != null) activeInfoWindow.close();
                        infowindow.open({
                            anchor: marker,
                            map,
                        });
                        //Save as active
                        activeInfoWindow = infowindow;
                    });

                return marker;
                
            })
        }

        if (path.length) {
 
            //Start/End
            const start = new maps.Marker({
                position: { lat: path.find(Boolean)?.lat, lng: path.find(Boolean)?.lng},
                sName: "",
                map: map,
                icon: {
                    path: maps.SymbolPath.CIRCLE,
                    scale: settings.polyline.ends.scale,
                    fillColor: color,
                    fillOpacity: 1,
                    strokeColor: color,
                    strokeOpacity: settings.polyline.ends.strokeOpacity,
                    strokeWeight: settings.polyline.ends.strokeWeight
                },
            });

            const end = new maps.Marker({
                position: { lat: path[path.length - 1]?.lat, lng: path[path.length - 1]?.lng},
                sName: "",
                map: map,
                icon: {
                    path: maps.SymbolPath.CIRCLE,
                    scale: settings.polyline.ends.scale,
                    fillColor: color,
                    fillOpacity: 1,
                    strokeColor: color,
                    strokeOpacity: settings.polyline.ends.strokeOpacity,
                    strokeWeight: settings.polyline.ends.strokeWeight
                },
            });
            
            //infowindows
            const infowindowStart = new maps.InfoWindow({
                content: startData.content,
                ariaLabel: startData.label,
            });

            const infowindowEnd = new maps.InfoWindow({
                content: endData.content,
                ariaLabel: endData.label,
            });

            //Listeners
            start.addListener("click", () => {
                infowindowEnd.close();
                infowindowStart.open({
                    anchor: start,
                    map,
                });
            });
            end.addListener("click", () => {
                infowindowStart.close();
                infowindowEnd.open({
                    anchor: end,
                    map,
                });
            });  
        }

            var activeInfoWindow;		

            //Check if all or just one route
            if (route) {
                const rpoi = route.fields.route_points_of_interest;
                drawMarkers(rpoi,maps);
            } else {
                !route && response.posts && Object.entries(response.posts).map((post, pindex) => {
                    const poi = post[1].fields.route_points_of_interest ?? null;
                    if (poi) {
                        return drawMarkers(poi,maps);
                    }
                }) 
            }
            
        //Line Distance
        const polylineLength = maps.geometry.spherical.computeLength(polyline.getPath());
        const route_distance = (parseFloat(polylineLength)/1000).toFixed(2); //To km
        
        let startPoint  = null;
        let endPoint    = null;
        let distances   = [];
        let kms = 0;
      
        if (route) {
            //Section Distance
            polyline.getPath().forEach(function(latLng) {
                endPoint = startPoint;
                startPoint = latLng;
                let distance = 0;
                if (endPoint) {
                    distance = maps.geometry.spherical.computeDistanceBetween(startPoint,endPoint);
                    kms = parseFloat(kms) +  parseFloat(distance.toFixed(2));
                    distances.push(kms.toFixed(2));
                }
            }); 

            // Initiate the path request.
            elevator
            .getElevationAlongPath({
                path: path,
                samples: path.length,
            })
            .then((results) => plotElevation({results, route_distance}))
            .catch((e) => {
                console.log(`[Can't get elevation] ${e}` );
                setElevationChart({});
                setElevation(getElevationData(0,route_distance));
            });
        }

  
        
        

    };
    
    const carousel = useRef()
    const gotoNext = () => {
        carousel?.current?.slickNext();
    }

    const handleApiLoaded = (map, maps) => {

        map.minZoom = settings.minzoom;

        //Define Bounds
        const bounds = new maps.LatLngBounds();

        
        //Draw routes
        if (response && showKML) {

            if (response.posts !== null ) {

                Object.keys(response.posts).forEach(function(post,index) {
                    const color = response.posts[post].fields.path_color ?? "#000000";
                    const route_path = response.posts[post].fields.route_path ?? [];
                    const rdata = response.posts[post];
        
                    
                    //Shadow Route
                    const rshadow = new maps.Polyline({
                        path: route_path,
                        strokeColor: color,
                        strokeOpacity: 0.3,
                        strokeWeight: settings.polyline.strokeWeight * 3,
                        map: map,
                    });

                    //Route
                    const rpath = new maps.Polyline({
                        path: route_path,
                        strokeColor: color,
                        strokeOpacity: 1,
                        strokeWeight: settings.polyline.strokeWeight,
                        map: map,
                 
                    });

                    route_path.map((coord, index) => {
                        bounds.extend(new maps.LatLng(coord.lat, coord.lng)); 
                    })
              
                    //Click event
                    maps.event.addListener(rshadow, 'click', function(e) {
                        handlePolyClick(rshadow,rdata);
                    });
                    maps.event.addListener(rpath, 'click', function(e) {
                        handlePolyClick(rpath,rdata);
                    });

                }) 

              
                
            }
            //Center the route to window
            map.fitBounds(bounds);
            map.setCenter(bounds.getCenter()); 

        }
        const handlePolyClick = (poly, data = null) => {
            if (data) {
                selectRoute(data);
                setChangeMapContainer(true);
                setShowInformationRoute(true);
                gotoNext()
            }
        }

        
        //Load KML layer
        // if (showKML) {
        if (false) {

            const Layer = new maps.KmlLayer({
                url: kmlurl,
                map: map,
                pane: "floatPane",
                preserveViewport: true
            });

            Layer.setOptions(
                {
                    suppressInfoWindows: true
                }
            );
            
            maps.event.addListener(Layer, 'click', function(kmlEvent) {
            //Move to
            const markerBounds = new maps.LatLngBounds();
            map.panTo(kmlEvent.latLng)
            map.fitBounds(markerBounds)
            map.setCenter(kmlEvent.latLng)
        });

        }

        //Route Bounds
       
        if (route !== null) {
            path.map((coord, index) => {
               bounds.extend(new maps.LatLng(coord.lat, coord.lng)); 
            })
            //Center the route to window
            map.fitBounds(bounds);
            map.setCenter(bounds.getCenter()); 
        }

        //Elevation
        const elevator = new maps.ElevationService();
        displayPathElevation(elevator, map, maps);

    };

    useEffect(() => {
        if (response?.data && Object.keys(response.data).length > 0) {
          setRoutes(response.data);
        }

      }, [response]);

    if (loading) {
        return <>{ 
            <div className="page-loader map">
              <img src={PageLoader}/>
            </div>
          }
          </>;
    }
    
    return (
        <div className="map" ref={componentRef}>
            <Filter setFilters={setFilters} filters={response.filters} trans={data.strings} showMenu={showFilter} setShowMenu={setShowFilter}/>
            <div className="container">
                <div className="map__section">
                    <div className='map__block'>
                        <div key={route ? route.data.ID : `full${Object.keys(response?.posts).reduce((a, b) => a + b, 0)}`}
                            className={changeMapContainer && dimensions.width > 1100 ? 'map__component-active' : 'map__component-disabled'}>
                            <GoogleMapReact
                                // onClick={onClick}
                                bootstrapURLKeys={
                                    {
                                        key: process.env.REACT_APP_GOOGLE_API,
                                        language: locale ?? 'en',
                                        region: locale ?? 'en',
                                        libraries:['drawing', 'geometry',  'visualization', 'localContext']
                                    }
                                }
                                defaultCenter={settings.center}
                                defaultZoom={settings.zoom}
                                options={{styles: mapStyles}}
                                layerTypes={[]}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            >                                               
                            </GoogleMapReact>
                        </div>
                        <div
                            className={changeMapContainer && dimensions.width > 1100 ? 'second__map-block' : 'second-block'}>
                            {changeMapContainer && dimensions.width >= 1100 && (<InformationRoute route={route} trans={data.strings} elevation={elevationData} />)}
                            {changeMapContainer && dimensions.width >= 1100 && (<ElevationRoute route={route} chart={elevationChart}/>)}
                        </div>
                    </div>
                    <SimpleSliderMap 
                        KMLSwitch={() => {KMLSwitch()}} 
                        selectRoute={(route)=> {selectRoute(route)}} 
                        route={route} 
                        getTerm={(query)=> {getTerm(query)}} 
                        routes={response.posts} 
                        trans={trans} 
                        setChangeMapContainer={setChangeMapContainer}
                        setShowInformationRoutes={setShowInformationRoute} 
                        setShowFilter={setShowFilter}
                        gotoNext={gotoNext}
                        carousel={carousel}
                    />
                    {showInformationRoute && dimensions.width <= 1100 && (<InformationRoute route={route} trans={data.strings} elevation={elevationData}/>)}
                    {showInformationRoute && dimensions.width <= 1100 && (<ElevationRoute route={route} chart={elevationChart}/>)}
                </div>
            </div>
        </div>
    );
};


export default Map;